import { Component, OnDestroy, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { MenuController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ProfileModel } from 'src/app/api/proxy/vitalscore/vitalscore-models';
import { SessionConfigResponseModel } from 'src/app/api/proxy/wearables/wearables-models';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { UserData } from 'src/app/services/auth-service/user-data.model';
import { UserInfoService } from 'src/app/services/auth-service/user-info-service';
import { DashboardService } from 'src/app/services/dashboard-service/dashboard-service';
import { EventService } from 'src/app/services/events/event.service';
import { OrganizationService } from 'src/app/services/organization-service/organization.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit, OnDestroy {

  isDashboardAllowed: boolean = false;
  chatEnabled: boolean = false;
  busyLoading: boolean = false;
  private subscriptions: Subscription[] = [];  // Store the subscription
  profile?: ProfileModel;
  emailAddress?: string;
  languageCode?: string;
  notificationCount?: number;
  devicesConnected?: number;
  constructor(
    private _navCtrl: NavController,
    private _DashboardService: DashboardService,
    private _authService: AuthService,
    private menu: MenuController,
    private _organizationService: OrganizationService,
    private _EventService: EventService,
    private _userService: UserInfoService
  ) {
    this.subscriptions.push(
      this._EventService.organizationConfigLoaded.subscribe(async (v: any) => {
        var chatEnabled = await this._organizationService.AIChatEnabled();
        this.chatEnabled = chatEnabled;
      }));

    this.subscriptions.push(
      this._EventService.myProfileUpdated.subscribe(async (profile: ProfileModel) => {
        this.profile = profile;
        var usr = await this._userService.getUserInfo();
        if (usr) {
          this.emailAddress = usr.email;
          this.languageCode = usr.language;
        }
      }));

    this.subscriptions.push(
      this._EventService.userUpdated.subscribe(async (usr: UserData) => {
        if (usr) {
          this.emailAddress = usr.email;
          this.languageCode = usr.language;
        }
      }));


    this.subscriptions.push(
      this._EventService.loadingEvent.subscribe(async (loading: number) => {
        this.busyLoading = loading > 0;
      }));

    this.subscriptions.push(
      this._EventService.notificationCount.subscribe(async (count: number) => {
        this.notificationCount = count;
      })
    );

    this.subscriptions.push(
      this._EventService.wearableConfigUpdted.subscribe(async (config: SessionConfigResponseModel) => {
        this.devicesConnected = 0;
        if (config) {
          if (config.appleHealthConnected)
            this.devicesConnected++;
          if (config.healthConnectConnected)
            this.devicesConnected++;
          if (config.webAuthorizers) {
            config.webAuthorizers.forEach((auth) => {
              if (!this.devicesConnected)
                this.devicesConnected = 0;
              if (auth.connected)
                this.devicesConnected++;
            });
          }

        }
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((a) => { a.unsubscribe() });
  }


  async ngOnInit(): Promise<void> {
    this.isDashboardAllowed = await this._DashboardService.userIsAllowed();
    this.chatEnabled = await this._organizationService.AIChatEnabled();
  }

  async logout() {
    await this.menu.close();
    await this._authService.logout('menu logout button');
  }

  async myProfile() {
    await this.menu.close();
    await this._navCtrl.navigateRoot('home/myProfile');
  }

  async mediaChatbot() {
    await this.menu.close();
    await this._navCtrl.navigateRoot('home/mediaChatbot');
  }

  async openUrl(theUrl: string) {
    await this.menu.close();
    setTimeout(async () => {
      await Browser.open({ url: theUrl });
    }, 10)
  }

  async deleteAccount() {
    await this.menu.close();
    await this._navCtrl.navigateRoot('deleteAccount');
  }

  async dashboard() {
    await this.menu.close();
    await this._navCtrl.navigateRoot('home/dashboard');
  }

  async gotoControlTest() {
    await this.menu.close();
    await this._navCtrl.navigateForward('control-demo');
  }

  async gotoMedia() {
    await this.menu.close();
    await this._navCtrl.navigateRoot('home/media');
  }

  async vivaScoreLite() {
    await this.menu.close();
    await this._navCtrl.navigateRoot('viva-lite/access-code');
  }

  async contactUs() {
    await this.menu.close();
    await this._navCtrl.navigateForward('home/contact-us');
  }

  async notifications() {
    await this.menu.close();
    await this._navCtrl.navigateForward('home/notification');
  }

  async wearables() {
    await this.menu.close();
    await this._navCtrl.navigateForward('home/wearables');
  }

  async changePassword() {
    await this.menu.close();
    await this._navCtrl.navigateForward('home/change-password');
  }

  async changeEmail() {
    await this.menu.close();
    await this._navCtrl.navigateForward('home/change-email');
  }
}